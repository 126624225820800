var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from '@material-ui/icons/Close';
import { ClientSideSuspense, LiveblocksProvider, RoomProvider, useCreateComment, useCreateThread, useMarkThreadAsResolved, useThreads } from "@liveblocks/react/suspense";
import { ErrorBoundary } from "@sentry/react";
import { Comment } from "@liveblocks/react-ui";
import { unstable_batchedUpdates } from "react-dom";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
function Room(_a) {
    var _this = this;
    var children = _a.children, roomId = _a.roomId, auth = _a.auth;
    return (React.createElement(LiveblocksProvider, { authEndpoint: function (room) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("/api/liveblocks-auth", {
                            method: "POST",
                            headers: {
                                Authorization: "Token ".concat(auth.token),
                                "Content-Type": "application/json",
                            },
                            // Don't forget to pass `room` down. Note that it
                            // can be undefined when using Notifications.
                            body: JSON.stringify({ room: room }),
                        })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); }, resolveUsers: function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
            var result, resJson;
            var userIds = _b.userIds;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, fetch('/api/my-teams/users', {
                            method: 'POST',
                            headers: {
                                Authorization: "Token ".concat(auth.token),
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ userIds: userIds }),
                        })];
                    case 1:
                        result = _c.sent();
                        return [4 /*yield*/, result.json()];
                    case 2:
                        resJson = _c.sent();
                        return [2 /*return*/, resJson.map(function (userData) { return ({
                                name: userData.user_name,
                            }); })];
                }
            });
        }); }, resolveMentionSuggestions: function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
            var result, users;
            var text = _b.text, roomId = _b.roomId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, fetch('/api/my-teams/users', {
                            method: 'POST',
                            headers: {
                                Authorization: "Token ".concat(auth.token),
                                "Content-Type": "application/json",
                            },
                        })];
                    case 1:
                        result = _c.sent();
                        return [4 /*yield*/, result.json()
                            // If there's a query, filter for the relevant users
                        ];
                    case 2:
                        users = _c.sent();
                        // If there's a query, filter for the relevant users
                        if (text) {
                            // Filter any way you'd like, e.g. checking if the name matches
                            users = users.filter(function (user) { return user.metadata.name.includes(text); });
                        }
                        // Return the filtered `userIds`
                        return [2 /*return*/, users.map(function (user) { return user.user_id; })];
                }
            });
        }); } },
        React.createElement(RoomProvider, { id: roomId, unstable_batchedUpdates: unstable_batchedUpdates },
            React.createElement(ErrorBoundary, null,
                React.createElement(ClientSideSuspense, { fallback: React.createElement("div", null, "Loading\u2026") }, children)))));
}
// A custom composer that creates a thread on submit
function MyComposer() {
    var createThread = useCreateThread();
    var _a = useState(''), comment = _a[0], setComment = _a[1];
    return React.createElement("input", { value: comment, onChange: function (e) { return setComment(e.target.value); }, onKeyDown: function (e) {
            e.preventDefault();
            if (e.key === 'Enter') {
                createThread({
                    body: { version: 1, content: [{ type: 'paragraph', children: [{ text: comment, }] }] },
                    metadata: {},
                });
                setComment('');
            }
        }, placeholder: "hey there" });
}
function TicketCreateThread(props) {
    var createThread = useCreateThread();
    var _a = useState(''), comment = _a[0], setComment = _a[1];
    var _b = useState(''), name = _b[0], setName = _b[1];
    var onSubmit = function () {
        var _a;
        createThread({
            body: { version: 1, content: [{ type: 'paragraph', children: [{ text: comment, }] }] },
            metadata: {
                name: name,
            },
        });
        (_a = props.onBack) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return React.createElement("div", { style: { background: '#F5F5F5', padding: '15px 10px' } },
        React.createElement("form", { onSubmit: onSubmit },
            React.createElement("div", null,
                React.createElement("label", { htmlFor: 'ticket-name', style: { fontWeight: 'bold' } }, "Nom*"),
                React.createElement("div", null,
                    React.createElement("input", { style: { width: '100%', background: '#F5F5F5' }, placeholder: 'Nom', required: true, name: 'ticket-name', value: name, onChange: function (e) { return setName(e.target.value); } }))),
            React.createElement("hr", null),
            React.createElement("div", null,
                React.createElement("textarea", { placeholder: 'Commentaire', style: { padding: 10, minHeight: 100, width: '100%', background: '#F5F5F5' }, value: comment, onChange: function (e) { return setComment(e.target.value); } })),
            React.createElement("div", { style: { justifyContent: 'space-between', display: 'flex' } },
                React.createElement("button", { onClick: props.onBack, className: "btn btn-secondary btn-sm smallIcon" }, "Retour"),
                React.createElement("button", { type: 'submit', disabled: name.trim().length === 0, className: "btn btn-primary btn-sm smallIcon", style: { border: '1px solid #40A5F4', width: '100%', } }, "Sauvergarder"))));
}
function ThreadView(props) {
    var markThreadAsResolved = useMarkThreadAsResolved();
    var createComment = useCreateComment();
    var onArchive = function () {
        markThreadAsResolved(props.thread.id);
        props.goBack();
    };
    var firstUser = props.thread.comments[0].userId;
    var _a = useState(''), comment = _a[0], setComment = _a[1];
    var onCommentSubmit = function () {
        if (comment.trim().length === 0) {
            return;
        }
        createComment({
            threadId: props.thread.id,
            body: { version: 1, content: [{ type: 'paragraph', children: [{ text: comment, }] }] },
        });
        setComment('');
    };
    return React.createElement("div", null,
        React.createElement("div", { style: { background: '#F5F5F5', padding: '16px 10px', marginBottom: 10 } },
            React.createElement("div", { style: { fontWeight: 'bold' } }, props.thread.metadata.name),
            firstUser,
            React.createElement("div", { style: { fontWeight: 'bold' } }, "Activit\u00E9"),
            React.createElement("div", { style: { overflowY: 'auto', maxHeight: 300 } }, props.thread.comments.map(function (comment) {
                if (!comment || !comment.body || comment.body.content.length === 0) {
                    return null;
                }
                return React.createElement(Comment, { key: comment.id, comment: comment });
            })),
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement("textarea", { placeholder: 'Commentaire', style: { padding: 10, minHeight: 100, width: '100%', background: '#F5F5F5' }, value: comment, onChange: function (e) { return setComment(e.target.value); } })),
                React.createElement("button", { onClick: onCommentSubmit, disabled: comment.trim().length === 0, className: "btn btn-primary btn-sm smallIcon", style: { border: '1px solid #40A5F4', width: '100%', } }, "Sauvergarder"))),
        React.createElement("div", { style: { justifyContent: 'space-between', display: 'flex' } },
            React.createElement("button", { onClick: props.goBack, className: "btn btn-secondary btn-sm smallIcon" }, "Retour"),
            React.createElement("button", { onClick: onArchive, className: "btn btn-secondary btn-sm smallIcon" }, "Archiver")));
}
function TicketDialogInside() {
    var _a;
    var _b = useThreads(), threads = _b.threads, error = _b.error;
    var _c = useState(false), isCreateThread = _c[0], setIsCreateThread = _c[1];
    var _d = useState(undefined), selectedThreadId = _d[0], setSelectedThreadId = _d[1];
    var selectedThread = selectedThreadId ? ((_a = threads.filter(function (thread) { return thread.id === selectedThreadId; })) === null || _a === void 0 ? void 0 : _a[0]) || undefined : undefined;
    if (selectedThread) {
        return React.createElement(ThreadView, { thread: selectedThread, goBack: function () { return setSelectedThreadId(undefined); } });
    }
    if (isCreateThread) {
        return React.createElement(TicketCreateThread, { onBack: function () { return setIsCreateThread(false); } });
    }
    var threadsUnresolved = threads === null || threads === void 0 ? void 0 : threads.filter(function (thread) { return !thread.resolved; });
    return React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: 16 } },
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8
            } }, threadsUnresolved ? threadsUnresolved.map(function (thread) { return (React.createElement("div", { key: thread.id, style: { background: '#F5F5F5', padding: '16px 10px', cursor: 'pointer' }, onClick: function () {
                setSelectedThreadId(thread.id);
            } },
            React.createElement("div", { style: { fontWeight: 'bold' } }, thread.metadata.name))); }) : null),
        React.createElement("button", { type: "button", className: "btn btn-secondary btn-sm smallIcon", style: { border: '1px solid #40A5F4', color: '#40A5F4' }, onClick: function () { return setIsCreateThread(true); } },
            React.createElement(AddCircleOutline, null),
            "\u00A0 Cr\u00E9er un conversation"));
}
function TicketDialog(props) {
    // const org = '1'
    // const group = '1'
    // const caseId = props.caseId
    return (React.createElement(TicketDialogInside, null));
}
export function CaseTicketDialog(props) {
    var _a = useState(false), isDragging = _a[0], setIsDragging = _a[1];
    var _b = useState({ x: 0, y: 0 }), position = _b[0], setPosition = _b[1];
    var _c = useState({ x: 0, y: 0 }), offset = _c[0], setOffset = _c[1];
    var startDrag = function (e) {
        setIsDragging(true);
        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };
    var drag = function (e) {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };
    var stopDrag = function () {
        setIsDragging(false);
    };
    var org = '1';
    var group = '1';
    var caseId = props.caseId;
    return (React.createElement(React.Fragment, null,
        React.createElement(Room, { roomId: "".concat(org, ":").concat(group, ":").concat(caseId), auth: props.auth }, props.open ? React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 100001,
                } },
                React.createElement(IconButton, { style: {
                        backgroundColor: '#1976d2',
                        color: 'white',
                        borderRadius: '50%',
                        width: '56px',
                        height: '56px',
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
                    }, onClick: function () { return props.onClose(); }, "aria-label": "open dialog" },
                    React.createElement("span", { style: { fontSize: '24px' } }, "+"))),
            React.createElement("div", { style: {
                    position: 'fixed',
                    top: "".concat(position.y, "px"),
                    left: "".concat(position.x, "px"),
                    background: 'white',
                    width: '380px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                    zIndex: '100000',
                    padding: '20px',
                    cursor: isDragging ? 'grabbing' : 'grab',
                    userSelect: 'none',
                }, onMouseDown: startDrag, onMouseMove: drag, onMouseUp: stopDrag, onMouseLeave: stopDrag },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', marginBottom: 20 } },
                    React.createElement("span", { style: {
                            fontSize: '20px',
                            color: '#000000',
                            fontWeight: 'bold',
                        } }, "Conversation"),
                    React.createElement(IconButton, { onClick: props.onClose, "aria-label": "close", size: "small" },
                        React.createElement(Close, null))),
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                    } },
                    React.createElement(TicketDialog, { auth: props.auth, caseId: props.caseId })))) : null)));
}
