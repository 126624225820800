var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { connect, useDispatch } from "react-redux";
import { addCase, createCaseVariant, deleteCase, getCaseVariants, patchCase, updateCase } from "../../../../actions/cases";
function formatDate(dateString) {
    if (!dateString) {
        return '';
    }
    var options = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    };
    var date = new Date(dateString);
    // Formatting the date in French locale
    return date.toLocaleDateString('fr-FR', options).replace('.', ''); // Removing period after month abbreviations
}
function ProvisionListItem(props) {
    // Local state for edit mode and input values
    var _a = useState(false), isEditingName = _a[0], setIsEditingName = _a[1];
    var _b = useState(false), isEditingDescription = _b[0], setIsEditingDescription = _b[1];
    var _c = useState(props.name || ""), name = _c[0], setName = _c[1];
    var _d = useState(props.description || ""), description = _d[0], setDescription = _d[1];
    var clickTimeout = null;
    // Handlers for toggling edit mode and saving changes
    var handleNameChange = function (event) {
        setName(event.target.value);
    };
    var handleDescriptionChange = function (event) {
        setDescription(event.target.value);
    };
    var onNameChangeDone = function () {
        var _a;
        if (!isEditingName) {
            return;
        }
        setIsEditingName(false);
        if (name != props.name) {
            (_a = props.changeName) === null || _a === void 0 ? void 0 : _a.call(props, name);
        }
    };
    var onDescriptionChangeDone = function () {
        var _a;
        if (!isEditingDescription) {
            return;
        }
        setIsEditingDescription(false);
        if (description != props.description) {
            (_a = props.changeDescription) === null || _a === void 0 ? void 0 : _a.call(props, description);
        }
    };
    var handleClick = function (e) {
        if (clickTimeout)
            clearTimeout(clickTimeout);
        clickTimeout = setTimeout(function () {
            props.onSelect(); // Single click action
        }, 200); // Delay time in milliseconds
    };
    var handleDoubleClick = function (e) {
        if (clickTimeout) {
            clearTimeout(clickTimeout); // Prevent single click action
        }
        setIsEditingName(true); // Double click action
    };
    return React.createElement("div", { style: { background: props.isActive ? "rgba(43, 153, 244, 0.2)" : "", borderRadius: 4, "padding": "4px 20px", } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            isEditingName ? (React.createElement("input", { type: "text", value: name, autoFocus: true, onChange: handleNameChange, onKeyDown: function (e) {
                    if (e.key === 'Enter') {
                        onNameChangeDone();
                    }
                }, onBlur: onNameChangeDone, style: { fontSize: '14px', fontWeight: 'bold' } })) : (React.createElement("span", { 
                // onClick={handleClick} // Single click action
                onDoubleClick: handleDoubleClick, style: { fontSize: "14px", color: "#000000", fontWeight: 'bold', cursor: 'pointer' } }, name)),
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: function () { return props.onDelete(); }, "aria-label": "Delete", size: "small" },
                    React.createElement(DeleteIcon, null)),
                React.createElement(IconButton, { onClick: function () { return props.onAdd(); }, "aria-label": "Delete", size: "small" },
                    React.createElement(AddCircleOutlineIcon, null)),
                React.createElement(IconButton, { onClick: function () { return props.onSelect(); }, "aria-label": "Check", size: "small" },
                    React.createElement(CheckIcon, null)))),
        React.createElement("div", { style: {
                "fontSize": "12px",
                "color": "#000000",
            } },
            "Cr\u00E9\u00E9 ",
            formatDate(props.creationDate),
            " - Modif. ",
            formatDate(props.modificationDate)),
        React.createElement("div", { style: {
                color: "#8E8E93",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                minHeight: 40
            } }, isEditingDescription ? (React.createElement("input", { autoFocus: true, type: "text", value: description, onChange: handleDescriptionChange, onKeyDown: function (e) {
                if (e.key === 'Enter') {
                    onDescriptionChangeDone();
                }
            }, onBlur: function () { return setIsEditingDescription(false); }, style: { fontSize: '12px', color: '#8E8E93' } })) : (React.createElement("span", { onDoubleClick: function () { return setIsEditingDescription(true); }, style: { cursor: 'pointer' } }, description || "No description"))));
}
function CaseProvisionDialogInternal(props) {
    var _this = this;
    var dispatch = useDispatch();
    if (!props.open || !props.variantParent) {
        return React.createElement(React.Fragment, null);
    }
    var allCaseVariants = __spreadArray([props.variantParent], props.caseVariants, true);
    var variantsSorted = allCaseVariants.sort(function (a, b) {
        if (a.date_created < b.date_created) {
            return -1;
        }
        if (a.date_created > b.date_created) {
            return 1;
        }
        return 0;
    });
    return React.createElement("div", { style: {
            position: 'fixed', background: 'white', width: '380px', borderRadius: '20px 0 0 20px', height: '100%',
            "right": "0",
            "top": 0,
            "zIndex": "100000",
            padding: '40px 20px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 40
        } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("span", { style: {
                    "fontSize": "20px",
                    "color": "#000000",
                    fontWeight: 'bold',
                } }, "Versions"),
            React.createElement(IconButton, { onClick: function () { return props.onClose(); }, "aria-label": "close", size: "small" },
                React.createElement(CloseIcon, null))),
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                rowGap: 4,
                overflowY: 'auto'
            } }, variantsSorted.sort().map(function (variant, index) {
            return React.createElement(ProvisionListItem, { key: variant.id, onAdd: function () { return __awaiter(_this, void 0, void 0, function () {
                    var newVariant;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(createCaseVariant(variant.id.toString(), props.variantParent.id.toString()))];
                            case 1:
                                newVariant = (_a.sent());
                                if (!newVariant || !newVariant.id) {
                                    return [2 /*return*/];
                                }
                                if (!props.variantParent) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, dispatch(patchCase(props.variantParent.id.toString(), { variant_view: newVariant.id }))];
                            case 2:
                                _a.sent();
                                window.location.replace("/dossier/".concat(variant.id));
                                return [2 /*return*/];
                        }
                    });
                }); }, name: variant.variant_title || variant.title, isActive: props.variantParent.variant_view === variant.id || (props.variantParent.variant_view === undefined && props.variantParent.id === variant.id), description: variant.variant_description, creationDate: variant.date_created, modificationDate: variant.date_modified, changeName: function (value) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(patchCase(variant.id.toString(), { id: variant.id, variant_title: value }))];
                            case 1:
                                _a.sent();
                                if (!props.variantParent) return [3 /*break*/, 3];
                                return [4 /*yield*/, dispatch(getCaseVariants(props.variantParent.id.toString()))];
                            case 2:
                                _a.sent();
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, changeDescription: function (value) {
                    dispatch(patchCase(variant.id.toString(), { id: variant.id, variant_description: value }));
                }, onDelete: function () { return __awaiter(_this, void 0, void 0, function () {
                    var confirmation;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?");
                                if (!confirmation) return [3 /*break*/, 3];
                                // show dialog
                                return [4 /*yield*/, dispatch(deleteCase(variant.id.toString()))];
                            case 1:
                                // show dialog
                                _a.sent();
                                if (!props.variantParent) return [3 /*break*/, 3];
                                return [4 /*yield*/, dispatch(getCaseVariants(props.variantParent.id.toString()))];
                            case 2:
                                _a.sent();
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, onSelect: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!props.variantParent) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, dispatch(patchCase(props.variantParent.id.toString(), { variant_view: variant.id }))];
                            case 1:
                                _a.sent();
                                window.location.replace("/dossier/".concat(variant.id));
                                return [2 /*return*/];
                        }
                    });
                }); } });
        })));
}
var mapStateToProps = function (state) {
    return { patchCase: patchCase };
};
export var CaseProvisionDialog = connect(mapStateToProps, { addCase: addCase, updateCase: updateCase })(CaseProvisionDialogInternal);
