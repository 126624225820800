var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { ADD_CASE, AUTH_ERROR, CASE_VARIANTS_LOADING, CASES_LOADING, CASES_RECEIVED, DELETE_CASE, DUPLICATE_CASE, GET_CASE, GET_CASE_VARIANTS, GET_VARIANT_PARENT, IS_CASE_LIST, IS_NOT_CASE_LIST, UPDATE_CASE_TITLE, UPDATE_CASE_VERSION } from "./types";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";
import { returnErrors } from "./errors";
// GET CASES
export var getCases = function () { return function (dispatch, getState) {
    dispatch({ type: CASES_LOADING });
    dispatch({ type: IS_CASE_LIST });
    axios
        .get("/api/", tokenConfig(getState))
        .then(function (res) {
        dispatch({ type: CASES_RECEIVED, payload: res.data });
    })
        .catch(function (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
}; };
// SET IS NOT CASE LIST
export var setIsNotCaseList = function () { return function (dispatch) {
    dispatch({ type: IS_NOT_CASE_LIST });
}; };
// DELETE CASE
export var deleteCase = function (id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, axios
                .delete("/api/".concat(id, "/"), tokenConfig(getState))
                .then(function () {
                dispatch(createMessage({ deleteCase: "Dossier supprimé" }));
                dispatch({ type: DELETE_CASE, payload: id });
            })
                .catch(function (err) { return console.log(err); })];
    });
}); }; };
// GET CASE
export var getCase = function (id, loadingParent) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!loadingParent) {
            dispatch({ type: CASES_LOADING });
        }
        return [2 /*return*/, axios
                .get("/api/".concat(id, "/"), tokenConfig(getState))
                .then(function (res) {
                var caseData = res.data;
                if (caseData.variant_view && caseData.variant_view !== caseData.id && !loadingParent) {
                    // Redirect to the URL specified in caseData
                    // window.location.href = `/dossier/${caseData.variant_view}`;
                    window.location.replace("/dossier/".concat(caseData.variant_view));
                    return;
                }
                if (!loadingParent) {
                    dispatch({ type: GET_CASE, payload: caseData });
                    if (caseData.variant_parent == null) {
                        dispatch({ type: GET_VARIANT_PARENT, payload: caseData });
                    }
                }
                else {
                    dispatch({ type: GET_VARIANT_PARENT, payload: caseData });
                }
                if (caseData === null || caseData === void 0 ? void 0 : caseData.variant_parent) {
                    dispatch(getCase(caseData.variant_parent.toString(), true));
                    dispatch(getCaseVariants(caseData.variant_parent.toString()));
                }
                else if (!loadingParent) {
                    dispatch(getCaseVariants(caseData.id.toString()));
                }
            })
                .catch(function (err) { return console.log(err); })];
    });
}); }; };
// GET CASE VARIANTS
export var getCaseVariants = function (id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: CASE_VARIANTS_LOADING });
        return [2 /*return*/, axios
                .get("/api/".concat(id, "/variants/"), tokenConfig(getState))
                .then(function (res) {
                dispatch({ type: GET_CASE_VARIANTS, payload: res.data });
            })
                .catch(function (err) { return console.log(err); })];
    });
}); }; };
export var createCaseVariant = function (id, variantParentId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: CASE_VARIANTS_LOADING });
        return [2 /*return*/, axios
                .post("/api/".concat(id, "/variants/"), {}, tokenConfig(getState))
                .then(function (res) {
                dispatch(getCaseVariants(variantParentId));
                return res.data;
            })
                .catch(function (err) { return console.log(err); })];
    });
}); }; };
// GET CASE VARIANTS
export var patchCase = function (id, caseVal) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        // dispatch({type: CASE_VARIANTS_LOADING});
        return [2 /*return*/, axios
                .patch("/api/".concat(id, "/"), caseVal, tokenConfig(getState))
                .then(function (res) {
                // dispatch({type: GET_CASE_VARIANTS, payload: res.data});
            })
                .catch(function (err) { return console.log(err); })];
    });
}); }; };
// ADD CASE
export var addCase = function (c) { return function (dispatch, getState) {
    axios
        .post("/api/", c, tokenConfig(getState))
        .then(function (res) {
        dispatch(createMessage({ addCase: "Dossier créé" }));
        dispatch({ type: ADD_CASE, payload: res.data });
    })
        .catch(function (err) {
        return dispatch(returnErrors(err.response.data, err.response.status));
    });
}; };
// UPDATE CASE
export var updateCase = function (c) { return function (dispatch, getState) {
    return axios
        .put("/api/".concat(c.id, "/"), c, tokenConfig(getState))
        .then(function (res) {
        dispatch(createMessage({ updateCase: "Modifications enregistrées" }));
        dispatch({ type: UPDATE_CASE_VERSION, payload: res.data.version });
        dispatch({ type: UPDATE_CASE_TITLE, payload: res.data.title });
    })
        .catch(function (err) {
        console.log(err);
        if (err && err.response && err.response.status === 409) {
            dispatch(createMessage({ recordConflict: "Echec de l'enregistrement. Votre dossier est vraisemblablement ouvert à deux emplacements distincts." }));
            return;
        }
        if (err && err.response && err.response.status === 401) {
            dispatch({ type: AUTH_ERROR });
            dispatch(createMessage({ disconnected: "Votre session a expiré, vous avez été déconnecté(e)" }));
            // dispatch(createMessage({recordConflict: "Echec de l'enregistrement. Le dossier a été supprimé." }));
            return;
        }
        dispatch(returnErrors(err.response.data, err.response.status));
        // dispatch(createMessage({disconnected: "Votre session a expiré, vous avez été déconnecté(e)"}));
    });
}; };
// DUPLICATE CASE
export var duplicateCase = function (id) { return function (dispatch, getState) {
    axios
        .post("/api/".concat(id, "/duplicate/"), {}, tokenConfig(getState))
        .then(function (res) {
        dispatch(createMessage({ addCase: "Dossier copié" }));
        dispatch({ type: DUPLICATE_CASE, payload: res.data });
    })
        .catch(function (err) { return console.log(err); });
}; };
