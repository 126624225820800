var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ADD_CASE, CASES_LOADING, CASES_LOADING_CANCEL, CASES_RECEIVED, DELETE_CASE, DUPLICATE_CASE, GET_CASE, GET_CASE_VARIANTS, GET_VARIANT_PARENT, IS_CASE_LIST, IS_NOT_CASE_LIST, UPDATE_CASE, UPDATE_CASE_TITLE, UPDATE_CASE_VERSION } from "../actions/types";
var initialState = {
    cases: [],
    isLoading: false,
    isLoadingVariants: false,
};
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case CASES_LOADING:
            return __assign(__assign({}, state), { isLoading: true, case: {} });
        case CASES_RECEIVED:
            return __assign(__assign({}, state), { cases: action.payload, isLoading: false });
        case CASES_LOADING_CANCEL:
            return __assign(__assign({}, state), { isLoading: false });
        case DELETE_CASE:
            return __assign(__assign({}, state), { cases: state.cases.filter(function (c) { return c.id !== action.payload; }) });
        case GET_CASE:
            return __assign(__assign({}, state), { case: action.payload, isLoading: false });
        case GET_VARIANT_PARENT:
            return __assign(__assign({}, state), { variantParent: action.payload, isLoading: false });
        case GET_CASE_VARIANTS:
            return __assign(__assign({}, state), { caseVariants: action.payload, isLoadingVariants: false });
        case IS_CASE_LIST:
            return __assign(__assign({}, state), { isCaseList: true });
        case IS_NOT_CASE_LIST:
            return __assign(__assign({}, state), { isCaseList: false });
        case UPDATE_CASE:
            return __assign(__assign({}, state), { case: action.payload });
        case UPDATE_CASE_TITLE:
            return __assign(__assign({}, state), { case: __assign(__assign({}, state.case), { title: action.payload }) });
        case UPDATE_CASE_VERSION:
            return __assign(__assign({}, state), { case: __assign(__assign({}, state.case), { version: action.payload }) });
        case ADD_CASE:
            return __assign(__assign({}, state), { cases: __spreadArray(__spreadArray([], state.cases, true), [action.payload], false), case: action.payload });
        case DUPLICATE_CASE:
            return __assign(__assign({}, state), { cases: __spreadArray(__spreadArray([], state.cases, true), [action.payload], false), case: action.payload });
        default:
            return state;
    }
}
