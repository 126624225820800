import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";

import {Link} from "react-router-dom";

import PropTypes from "prop-types";
import {deleteCase, getCases, setIsNotCaseList} from "../../actions/cases";
import {formatDate} from "./utils";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import DeleteCaseButton from "./DeleteCaseButton";
import DuplicateCaseButton from "./DuplicateCaseButton";
import ExpiredAccountLink from "../users/ExpiredAccountModal";
import {getTeamsAndMembers} from "../../actions/teams";


const Cases = ({
                   cases,
                   getCases,
                   getTeamsAndMembers,
                   isLoading,
                   auth,
                   teams,
                   setIsNotCaseList
               }) => {
    const [search, setSearch] = useState("");
    const [searchByCreatorId, setSearchByCreatorId] = useState("");
    const [filterLoaded, setFilterLoaded] = useState(false);

    useEffect(() => {
        if (!auth || filterLoaded) {
            return
        }
        if (auth.user) {
            setSearchByCreatorId(String(auth.user.id))
        }
        setFilterLoaded(true)
    }, [auth, filterLoaded]);

    useEffect(() => {
        if (auth.userUpdateLoading || !filterLoaded) {
            return
        }
        let authVal = auth.user.preference_filter_user_id
        if (!authVal) {
            authVal = ""
        }

        if (auth && String(authVal) !== searchByCreatorId) {
            let updateVal = null
            if (searchByCreatorId.length > 0) {
                updateVal = searchByCreatorId
            }
        }
    }, [auth, searchByCreatorId, filterLoaded]);

    const caseList = useMemo(() => {
        let updatedList = [...cases];
        if (searchByCreatorId.length === 0 && search.length === 0) {
            return updatedList
        }

        updatedList = updatedList.filter((item) => {
            if (!item || !item.title) {
                return false;
            }
            if (searchByCreatorId.length > 0) {
                if (String(item.created_by_id) !== searchByCreatorId) {
                    return false;
                }
            }

            if (search.length === 0) {
                return true;
            }

            return item.title.toLowerCase().includes(search.toLowerCase());
        });
        return updatedList
    }, [cases, search, searchByCreatorId])

    const userIdToUser = useMemo(() => {
        const idToUser = {}

        if (teams && teams.length > 0) {
            for (const team of teams) {
                for (const member of team.members) {
                    idToUser[member.user_id] = {email: member.user_email, name: member.user_name};
                }
            }
        }
        if (auth && auth.user) {
            idToUser[auth.user.id] = {email: auth.user.email, name: auth.user.name};
        }

        return idToUser
    }, [teams, auth])

    const hasMultipleUsers = useMemo(() =>
        Object.keys(userIdToUser).length > 1, [userIdToUser])

    useEffect(() => {
        getTeamsAndMembers();
        getCases();
        return () => {
            setIsNotCaseList();
        };
    }, [getTeamsAndMembers, getCases, setIsNotCaseList]);

    const filterBySearch = useCallback((e) => {
        const query = e.target.value;
        setSearch(query);
    }, [])

    const filterByCreatorId = useCallback((e) => {
        setSearchByCreatorId(e.target.value);
    }, []);

    const getNameByUserId = useCallback((userId) => {
        return userIdToUser[userId] ? userIdToUser[userId].name || userIdToUser[userId].email || "" : "";
    }, [userIdToUser])

    return (
        <Fragment>
            <div className="container-fluid row mt-3 d-flex justify-content-between align-items-end">
                <h2>
                    Mes <br/>
                    <strong>dossiers</strong>
                </h2>

                <div style={{display: "flex", gap: 16}} className="mb-2">
                    <div className="mb-2" style={{minWidth: "300px", alignContent: 'end'}}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="text"
                                name="search"
                                onChange={filterBySearch}
                                placeholder="Filtrer par nom de dossier"
                                className="form-control form-control-sm"
                            />
                        </form>
                    </div>
                    {teams && teams.length > 0 && (
                        <div className="mb-2">
                            <select
                                id="filterSelect"
                                value={searchByCreatorId}
                                onChange={filterByCreatorId}
                                style={{padding: 8, paddingRight: 16, border: "none"}}
                            >
                                <option value={auth.user.id} default>Mes dossiers</option>
                                <option value="">Tous les dossiers de l'équipe</option>
                                {teams[0].members.sort((a,b) => a.user_email.localeCompare(b.user_email)).filter((entry) => entry.user_id !== auth.user.id).map((entry) => (
                                    <option key={entry.id} value={entry.user_id}>
                                        {'Dossiers de ' + getNameByUserId(entry.user_id)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>

                <div className="">
                    {auth && auth.user && !auth.user.subscription_expired ? (
                        <Link to="/nouveau" id="create_new_case">
                            <CreateNewFolderIcon fontSize="large"/> Créer un dossier
                        </Link>
                    ) : (
                        <ExpiredAccountLink
                            title={
                                <>
                                    <CreateNewFolderIcon fontSize="large"/> Créer un dossier
                                </>
                            }
                            expiryDate={auth.user.subscription_end_date}
                            isAccountManager={auth && auth.user && auth.user.stripe_id}
                            isBetaTester={auth && auth.user && auth.user.is_beta_tester}
                        />
                    )}
                </div>
            </div>
            <table className="table table-striped mb-5">
                <thead>
                <tr>
                    <th></th>
                    <th>Nom du dossier</th>
                    <th>Créé le</th>
                    <th>Modifié le</th>
                    {hasMultipleUsers ? <th>Gestionnaire</th> : null}
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {cases.length > 0 ? (
                    caseList.map((c) => (
                        <tr key={c.id}>
                            <td className="align-middle">{c.id}</td>
                            <td className="align-middle">
                                {auth && auth.user && !auth.user.subscription_expired ? (
                                    <Link to={`/dossier/${c.id}`}>{c.title}</Link>
                                ) : (
                                    <ExpiredAccountLink
                                        title={c.title}
                                        expiryDate={auth.user.subscription_end_date}
                                        isAccountManager={auth && auth.user && auth.user.stripe_id}
                                        isBetaTester={auth && auth.user && auth.user.is_beta_tester}
                                    />
                                )}
                            </td>
                            <td className="align-middle">
                                {formatDate(c.date_created, 0, true)}
                            </td>
                            <td className="align-middle">
                                {c.date_created.slice(0, 19) !== c.date_modified.slice(0, 19)
                                    ? formatDate(c.date_modified, 0, true)
                                    : "-"}
                            </td>
                            {hasMultipleUsers ?
                                <td className="align-middle">{getNameByUserId(c.created_by_id)}</td> : null}
                            <td className="align-middle">
                                <DuplicateCaseButton legalCase={c}/>
                            </td>
                            <td className="align-middle">
                                <DeleteCaseButton legalCase={c}/>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={hasMultipleUsers ? 7 : 6}>
                            {isLoading
                                ? "Chargement de vos dossiers en cours..."
                                : (
                                    <>
                                        Pas de dossier à afficher,{" "}
                                        <Link to="/nouveau">créez votre premier dossier</Link>
                                    </>
                                )}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </Fragment>
    );
};

Cases.propTypes = {
    cases: PropTypes.array.isRequired,
    getCases: PropTypes.func.isRequired,
    getTeamsAndMembers: PropTypes.func.isRequired,
    teams: PropTypes.array.isRequired,
    deleteCase: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    auth: PropTypes.object
};

const mapStateToProps = state => ({
    cases: state.cases.cases,
    isLoading: state.cases.isLoading,
    teams: state.teams.teams,
    teamsLoading: state.teams.isLoading,
    auth: state.auth
});

export default connect(mapStateToProps, {getCases, deleteCase, setIsNotCaseList, getTeamsAndMembers})(Cases);
