import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteCase } from "../../actions/cases";
import { connect, useDispatch } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
var DeleteCaseButton = function (props) {
    var legalCase = props.legalCase;
    var dispatch = useDispatch();
    var caseId = legalCase.id;
    var caseTitle = legalCase.title;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleConfirm = function (id) {
        dispatch(deleteCase(id));
        setOpen(false);
    };
    return (React.createElement("div", null,
        React.createElement(Tooltip, { title: "Supprimer le dossier", placement: "left" },
            React.createElement(IconButton, { name: "delete-case-button", color: "secondary", size: "small", onClick: handleClickOpen },
                React.createElement(DeleteIcon, null))),
        open ? React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
            React.createElement(DialogTitle, { id: "alert-dialog-title" },
                "Supprimer le dossier ",
                caseTitle,
                " ?"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { id: "alert-dialog-description" },
                    "Souhaitez-vous vraiment supprimer le dossier ",
                    caseTitle,
                    " ?")),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose, color: "primary", size: "small", title: "Supprimer le dossier" }, "Annuler"),
                React.createElement(Button, { name: "confirm-delete-button", onClick: function () { return handleConfirm(caseId); }, variant: "contained", color: "secondary", size: "small", startIcon: React.createElement(DeleteIcon, null), autoFocus: true }, "Confirmer la suppression"))) : null));
};
export default connect()(DeleteCaseButton);
