import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Field,
  FieldArray,
  getFormValues,
  getFormMeta,
} from "redux-form";
import { formatDateField } from "../../utils";
import RenderActionsCol from "./renderActionsCol";
import { getLineAmount, getDsfLineSummary } from "../calculations";
import renderDSFPast from "./renderDSFPast";
import renderDSFFuture from "./renderDSFFuture";
import RenderDSFLineSummary from "./renderDSFLineSummary"

const RenderDSF = props => {
  const { fields, item, victim, values, dispatch, meta, entry, itemId } = props;

  let changeCheckArray = [values[fields.name], victim, null];
  if (values["TPP"] && values["TPP"][fields.name]) changeCheckArray[2] = values["TPP"][fields.name];

  if (fields.length === 0) {
    let firstLine = { arr: { past: [{}], future: [{ frequencyType: 1 }], annCap: { annuityPercentage: 100, annuityPeriodicity: 12 } } };
    if (victim && victim.consolidationDate) {
      let consolidationDate = new Date(victim.consolidationDate.split("-")[0],parseInt(victim.consolidationDate.split("-")[1])-1,victim.consolidationDate.split("-")[2]);
      firstLine.arr.past[0].startDate = formatDateField(consolidationDate.getTime() + 86400000);
    }
    fields.push(firstLine);
  }

  const removeLine = index => {
    fields.remove(index);
  };

  return (
    <tbody>
      <Fragment>
        {fields.map((line, index) => (
          <Fragment key={index}>
            <tr>
              <th scope="row" className="col-num main-line">
                <div className="dsf-line-num">{index + 1}.</div>
              </th>
              <td className="main-line" colSpan="4">
                <div className="input-group input-group-sm flex-nowrap">
                  <Field
                    name={`${line}.label`}
                    component="input"
                    type="text"
                    className="form-control dsf-line-label"
                    placeholder="Intitulé de la dépense"
                  /></div>
              </td>
              <RenderActionsCol
                item={item}
                lineAmount={getLineAmount(values, fields.name, index, item).toFixed(2)}
                line={line}
                removeLine={() => removeLine(index)}
              />
            </tr>
            {values[fields.name][index].arr &&
              values[fields.name][index].arr.past
              &&
              (
                <FieldArray
                  name={`${line}.arr.past`}
                  line={line}
                  item={item}
                  lineIndex={index}
                  component={renderDSFPast}
                  entryMeta={meta[fields.name]}
                  arrValues={values[fields.name][index].arr.past}
                  tppValues={values[fields.name][index].arr.past_tpp}
                  values={values}
                  victim={victim}
                  entry={fields.name}
                />
              )}
            {values[fields.name][index].arr &&
              (values[fields.name][index].arr.future || (values[fields.name][index].arr.annCap && values[fields.name][index].arr.annCap.tppCap && values[fields.name][index].arr.annCap.tppCap.length > 0))
              &&
              (
                <FieldArray
                  name={`${line}.arr.future`}
                  line={line}
                  item={item}
                  lineIndex={index}
                  component={renderDSFFuture}
                  entryMeta={meta[fields.name]}
                  arrValues={values[fields.name][index].arr.future}
                  values={values}
                  victim={victim}
                  entry={fields.name}
                />
              )}
            {fields.length > 1 &&
              values[fields.name][index].arr &&
              values[fields.name][index].arr.past && values[fields.name][index].arr.past.length > 0 &&
              values[fields.name][index].arr.future && values[fields.name][index].arr.future.length > 0 &&
              (<RenderDSFLineSummary

                index={index}
                victim={victim}
                values={values}
                fields={fields}
                item={item}
              />)
            }
            {fields.length > 1 && getDsfLineSummary(values, fields.name, index, item, victim).hasPreferenceRight &&
              <tr>
                <td />
                <td colSpan="4" className="text-right pt-0">
                  <small className="text-danger">
                    <strong>En application du droit de préférence, la créance de la victime et le recours du/des tiers payeur(s) s'apprécient sur l'ensemble de la dépense, arrérages échus et à échoir confondus.</strong>
                  </small>
                </td>
                <td />
              </tr>
            }
            {getDsfLineSummary(values, fields.name, index, item, victim).tppSurplus > 0 && (
              <tr>
                <td />
                <td colSpan={4} className="text-right align-bottom pt-0 pb-0">
                  <small className="text-danger">
                    <strong>Le reliquat de paiement de tiers payeur(s) non imputé sur cette dépense de {Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(getDsfLineSummary(values, fields.name, index, item, victim).tppSurplus)} n'est pas reporté sur les autres dépenses ou les autres postes de préjudice.</strong>
                  </small>
                </td>
                <td />
              </tr>
            )}
          </Fragment>
        ))}
      </Fragment>
    </tbody>
  );
};

RenderDSF.propTypes = {
  fields: PropTypes.object,
  item: PropTypes.object,
  victim: PropTypes.object,
  values: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  entry: PropTypes.string,
  itemId: PropTypes.number
};

export default connect(state => ({
  values: getFormValues("caseDataForm")(state),
  meta: getFormMeta("caseDataForm")(state)
}))(RenderDSF);
